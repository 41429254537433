import { Link, useLocation } from 'react-router-dom';

import { Layout, Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUsers,
  faHouse,
  faDashboard,
  faHardDrive,
  faLocationDot,
  faPlug,
} from '@fortawesome/free-solid-svg-icons';
import LogoBase from 'assets/images/cube.png';
import { I18n } from 'i18n';
import './style.less';

const ROUTES = [
  { name: 'farms', path: 'farms', icon: faHouse },
  { name: 'users', path: 'users', icon: faUsers },
  { name: 'data_points', path: 'data-points', icon: faLocationDot },
  { name: 'devices', path: 'devices', icon: faHardDrive },
  { name: 'integrator', path: 'integrator', icon: faPlug },
];

export function Sider() {
  const { pathname } = useLocation();
  const location = pathname.split('/');
  return (
    <Layout.Sider className="sider-menu">
      <div className="container-logo">
        <div className="bg-isoogo">
          <img src={LogoBase} alt="Logo" />
        </div>
        <h2>iRubik</h2>
      </div>
      <Menu mode="inline" className="menu" defaultSelectedKeys={[location[1]]}>
        <Menu.ItemGroup
          style={{ color: '#0f1c30', fontWeight: 400 }}
          key="analytics"
          title={I18n('analytics', true, true)}
        >
          <Menu.Item className="menu-item" key="dashboard" style={{ float: 'right' }}>
            <FontAwesomeIcon icon={faDashboard} />
            <Link to={'/dashboard'}>{I18n('dashboard', true)}</Link>
          </Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup
          style={{ color: '#0f1c30', fontWeight: 400 }}
          key="manage"
          title={I18n('manage', true, true)}
        >
          {ROUTES.map(({ name, path, icon }) => (
            <Menu.Item className="menu-item" key={path}>
              <FontAwesomeIcon icon={icon} />
              <Link to={'/' + path}>{I18n(name, true)}</Link>
            </Menu.Item>
          ))}
        </Menu.ItemGroup>
      </Menu>
    </Layout.Sider>
  );
}
